import { client } from './oidc';

client.handleAuthentication()
    .then(async ({ shouldRender }) => {
        if (shouldRender) {
            const render = await import('./app');
            render.default();
        }
    })
    .catch(async (err) => {
        await console.error(err);
    })
